/**
 * File to contain Close SVG
 * @function
 */
import React from 'react';
import { string, number } from 'prop-types';
const Close = ({ componentName, stroke, strokeWidth, dimensions, viewBox }) => {
	return (
		<svg
			width={dimensions}
			height={dimensions}
			viewBox={viewBox}
			className={componentName}
		>
			<path
				className='path'
				fill='none'
				stroke={stroke}
				strokeWidth={strokeWidth}
				d='M3,3 L21,21 M3,21 L21,3'
			/>
		</svg>
	);
};

Close.propTypes = {
	/**
	 * Define component name
	 */
	componentName: string,
	/**
	 * Define stroke color
	 */
	stroke: string,
	/**
	 * Define stroke width
	 */
	strokeWidth: number,
	/**
	 * Define dimensions of svg
	 */
	dimensions: string,
	/**
	 * Define viewbox of svg
	 */
	viewBox: string,
};

Close.defaultProps = {
	componentName: 'close',
	stroke: '#000',
	strokeWidth: 2,
	dimensions: '15',
	viewBox: '0 0 24 24',
};
export default Close;
