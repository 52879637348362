import App from '../App';
import configs from '../Configs';
import Heading from 'sharp-component-library/build/Heading';
import { AuthProvider, AuthService, useAuth } from 'react-oauth2-pkce';

window.Buffer = window.Buffer || require("buffer").Buffer;

const authEndpoint = configs().secureauth.authUrl;

const authService = new AuthService({
    clientId: configs().secureauth.clientId,
    location: window.location.href,
    authorizeEndpoint: `${authEndpoint}SecureAuth.aspx`,
    tokenEndpoint: `${authEndpoint}OidcToken.aspx`,
    logoutEndpoint: `${authEndpoint}OidcEndSession.aspx`,
    redirectUri: configs().secureauth.redirectUri,
    scopes: ['openid']
});

const SecureApp = () => {
    const { authService } = useAuth();

    // set a timer to restart the login if we're stuck in pending state
    const delayLogin = () => {
      setTimeout(() => {
        authService.removeItem('pkce');
        authService.login();
      }, 3000)
    };

    if(authService.isPending()){
      delayLogin();
        return (
          <div data-testid={'login-pending'}>
            <Heading level={2}>Sharp SSO - Please wait...</Heading>
          </div>
        );
    }
    
    if (!authService.isAuthenticated()) {
        return (
          <div data-testid={'login'}>
            {authService.login()}
          </div>
        );
    } else if(authService.getAuthTokens().expires_at){
        const now = new Date();
        const exp = new Date(authService.getAuthTokens().expires_at);
        if(now > exp){
          authService.removeItem('pkce');
          authService.removeItem('auth');
          return (
            <div data-testid={'token-expired'}>
              {authService.login()}
            </div>
          );
        }
    } else {
      authService.login();
    }

    return (
        <div data-testid={'app'}>
          <App />
        </div>
    );
}

const SecureWrapper = () => {
    return (
        <div data-testid='secure-wrapper'>
          <AuthProvider authService={authService} >
              <SecureApp />
          </AuthProvider>
        </div>
    );
}

export default SecureWrapper;