import { useState, useCallback } from 'react';

export const useBoolean = (initialState = false) => {
	const [state, setState] = useState(initialState);

	// function useCallback does not require or accept additional arguments
	// eslint-disable-next-line
	const toggle = useCallback(() => setState((state) => !state, []));

	return [state, toggle];
};

export const useHandleInput = (initialValue) => {
	const [value, setValue] = useState(initialValue);

	const handleChange = (event) => {
		setValue(event.target.value);
	};

	return {
		value,
		onChange: handleChange,
	};
};
