/**
 * File containing REPRESENTATION of Table, tr, and td html elements as individual components
 * @function
 */

import React from 'react';
import { node } from 'prop-types';

const TrRow = ({ children }) => {
	return (
		<div data-testid='tr-row' className='tr is-row'>
			{children}
		</div>
	);
};

TrRow.propTypes = {
	/**
	 * Pass a node as a children to element
	 */
	children: node.isRequired,
};

export default TrRow;
