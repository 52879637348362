/**
 * File to contain Card component for Adding a new provider
 * @function
 */

import React, { memo } from 'react';
import { string, func, bool, object } from 'prop-types';
import Table from './Table';
import TrRow from './Table.TrRow';
import TrCol from './Table.TrCol';
import Td from './Table.Td';
import TextField from 'sharp-component-library/build/TextField';
import CardFooter from './Card.Footer';
import Loading from './Loading';
import Error from './Error';
import Close from '../assets/Close';

import Heading from 'sharp-component-library/build/Heading';

const AddNewProvider = ({
	componentName,
	submitCancel,
	submitSave,
	loading,
	error,
	errorMsg,
	errorRef,
	handleClose,

	// name props
	nameFirstOnChange,
	nameFirstValue,
	nameFirstError,
	nameFirstErrorText,

	nameLastOnChange,
	nameLastValue,
	nameLastError,
	nameLastErrorText,
}) => {
	return (
		<div
			className={`card ${componentName}`}
			data-testid={componentName}
			style={{
				border: !!error && 'solid 1px #ba4534',
				transition: !!error && 'border 1s',
			}}
		>
			<div className='intro'>
				<Heading level={3}>Add new provider</Heading>
				<div className='edit' data-testid='edit'>
					<button data-testid='close-btn' onClick={submitCancel}>
						<Close stroke={'#000'} />
					</button>
				</div>
			</div>
			<div className='content' data-testid='content'>
				<Table>
					<TrRow>
						<Td>
							First name <span className='required'> *</span>
						</Td>
						<Td>
							Last name <span className='required'> *</span>
						</Td>
					</TrRow>
					<TrRow>
						<TrCol>
							<TextField
								name='First name'
								type='text'
								className='add-new-textfield'
								onChange={nameFirstOnChange}
								aria-label='First name'
								value={nameFirstValue}
								data-testid='first-name'
								isError={nameFirstError}
								validationText={nameFirstErrorText}
							/>
						</TrCol>
						<TrCol>
							<TextField
								name='Last name'
								type='text'
								className='add-new-textfield'
								onChange={nameLastOnChange}
								aria-label='Last name'
								value={nameLastValue}
								data-testid='last-name'
								isError={nameLastError}
								validationText={nameLastErrorText}
							/>
						</TrCol>
					</TrRow>
				</Table>
			</div>

			<div className={!!loading || !!error ? 'expand-status' : 'status'}>
				{!!error && (
					<Error
						handleClose={handleClose}
						errorMsg={errorMsg}
						errorRef={errorRef}
					/>
				)}

				{!!loading && <Loading defaultText='Loading' />}
			</div>

			{!loading && (
				<CardFooter submitCancel={submitCancel} submitSave={submitSave} />
			)}
		</div>
	);
};

AddNewProvider.propTypes = {
	/**
	 * Define component name
	 */
	componentName: string,
	// first name props
	nameFirstOnChange: func,
	nameFirstValue: string,
	nameFirstError: bool,
	nameFirstErrorText: string,
	// last name props
	nameLastOnChange: func,
	nameLastValue: string,
	nameLastError: bool,
	nameLastErrorText: string,
	// PROPS FOR CARD.FOOOTER
	/**
	 * Function for submitting cancel
	 */
	submitCancel: func,
	/**
	 * Function for submitting save
	 */
	submitSave: func,
	/**
	 * Prop to display error
	 */
	error: bool.isRequired,
	/**
	 * Define error message
	 */
	errorMsg: string,
	/**
	 * Prop for error element ref
	 */
	errorRef: object,
	/**
	 * handle functionality to close error message
	 */
	handleClose: func,
};

AddNewProvider.defaultProps = {
	componentName: 'add-new-provider',
};

export default memo(AddNewProvider);
