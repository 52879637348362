import axios from 'axios';
import configs from '../../Configs';
import { useAuth } from 'react-oauth2-pkce';

export default function getAxiosClient() {
    const { authService } = useAuth();
    const { access_token } = authService.getAuthTokens();
    return axios.create({
      baseURL: configs().api.endpoint,
      headers: { Authorization: `Bearer ${access_token}` }
    });
};