import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

const Logout = React.lazy(() => import("../components/Logout"));
const Home = React.lazy(() => import("./find-a-doctor/Home"));
const EditResource = React.lazy(() => import("./admin/EditResource"));
const FindADoctor = React.lazy(() => import("./find-a-doctor/FindADoctor"));
const Provider = React.lazy(() => import("./find-a-doctor/Provider"));
const Locations = React.lazy(() => import("./locations/Locations"));
const Location = React.lazy(() => import("./locations/Location"));
const LocationsHome = React.lazy(() => import("./locations/LocationsHome"));
const Insurance = React.lazy(() => import("./insurance/Insurance"));
const InsuranceLists = React.lazy(() => import("./insurance/InsuranceLists"));
const Entities = React.lazy(() => import("./entities/Entities"));


const RouterWrapper = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="logout"
          element={
            <React.Suspense fallback={<>...Loading...</>}>
              <Logout />
            </React.Suspense>
          }
        />
        <Route
          path="/find-a-doctor"
          data-testid='fad-route'
          element={
            <React.Suspense fallback={<>...Loading...</>}>
              <FindADoctor />
            </React.Suspense>
          }
        >
          <Route
            path="providers"
            element={
              <React.Suspense fallback={<>...Loading...</>}>
                <Home />
              </React.Suspense>
            }
          />
          <Route
            path="providers/:physicianId"
            element={
              <React.Suspense fallback={<>...Loading...</>}>
                <Provider />
              </React.Suspense>
            }
          />
          <Route
            path=":resource"
            element={
              <React.Suspense fallback={<>...Loading...</>}>
                <EditResource />
              </React.Suspense>
            }
          >
            <Route
              path=":id"
              element={
                <React.Suspense fallback={<>...Loading...</>}>
                  <EditResource />
                </React.Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          path="/locations"
          element={
            <React.Suspense fallback={<>...Loading...</>}>
              <Locations />
            </React.Suspense>
          }
        >
          <Route
            path="locations"
            element={
              <React.Suspense fallback={<>...Loading...</>}>
                <LocationsHome />
              </React.Suspense>
            }
          />
          <Route
            path="locations/:locationId"
            element={
              <React.Suspense fallback={<>...Loading...</>}>
                <Location />
              </React.Suspense>
            }
          />
          <Route
            path=":resource"
            element={
              <React.Suspense fallback={<>...Loading...</>}>
                <EditResource />
              </React.Suspense>
            }
          >
            <Route
              path=":id"
              element={
                <React.Suspense fallback={<>...Loading...</>}>
                  <EditResource />
                </React.Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          path="/insurance"
          element={
            <React.Suspense fallback={<>...Loading...</>}>
              <Insurance />
            </React.Suspense>
          }
        >
          <Route
            path="insurance-lists"
            element={
              <React.Suspense fallback={<>...Loading...</>}>
                <InsuranceLists />
              </React.Suspense>
            }
          >
            <Route
              path=":id"
              element={
                <React.Suspense fallback={<>...Loading...</>}>
                  <InsuranceLists />
                </React.Suspense>
              }
            />
          </Route>
          <Route
            path=":resource"
            element={
              <React.Suspense fallback={<>...Loading...</>}>
                <EditResource />
              </React.Suspense>
            }
          >
            <Route
              path=":id"
              element={
                <React.Suspense fallback={<>...Loading...</>}>
                  <EditResource />
                </React.Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          path="/entities"
          element={
            <React.Suspense fallback={<>...Loading...</>}>
              <Entities />
            </React.Suspense>
          }
        >
          <Route
            path=":resource"
            element={
              <React.Suspense fallback={<>...Loading...</>}>
                <EditResource />
              </React.Suspense>
            }
          >
            <Route
              path=":id"
              element={
                <React.Suspense fallback={<>...Loading...</>}>
                  <EditResource />
                </React.Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          path="/"
          element={
            <React.Suspense fallback={<>...Loading...</>}>
              <Navigate to="/find-a-doctor/providers" replace={true} />
            </React.Suspense>
          }
        />
      </Routes>
    </Router>
  );
};

export default RouterWrapper;
