/**
 * File to contain Find a doctor Home page container
 * @function
 */

import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../utils/context';
import { string } from 'prop-types';
import getAxiosClient from '../../utils/api/axiosClient';
import TableGrid from '../../components/Table.Grid';
import AddNewProvider from '../../components/Card.AddNewProvider';
import Button from 'sharp-component-library/build/Button';
import TextField from 'sharp-component-library/build/TextField';
import SearchIcon from '../../assets/magnifying-glass.svg';
import Heading from 'sharp-component-library/build/Heading';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import Animation from '../../components/Animation';
import { dataModel } from '../admin/dataMap';

function DebouncedSearchInput({
	value: initialValue,
	onChange,
	debounce = 500,
	...props
}) {
	const [value, setValue] = useState(initialValue);

	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			onChange(value);
		}, debounce);

		return () => clearTimeout(timeout);
	}, [value]);

	return (
		<TextField
			{...props}
			hideLabel='true'
			value={value}
			onChange={(e) => setValue(e.target.value)}
		/>
	);
}

const Home = ({ componentName }) => {
	const { errorRef, errorAnimate, error, setError, setToggleBasicInformation } =
		useContext(AppContext);

	const navigate = useNavigate();
	const axiosClient = getAxiosClient();
	const resource = 'providers';
	const { title: gridTitle, map: cols, key: resourceKey } = dataModel[resource];
	const [data, setData] = useState([]);
	const [queryFilter, setQueryFilter] = useState(
		'filter[name_formatted][ilike]='
	);
	const [query, setQuery] = useState('');
	const [addNewProvider, setAddNewProvider] = useState(false);
	const [nameFirstValue, setNameFirstValue] = useState('');
	const [nameLastValue, setNameLastValue] = useState('');
	const [nameFirstError, setNameFirstError] = useState(false);
	const [nameLastError, setNameLastError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [addNewLoading, setAddNewLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');

	const getProviders = async () => {
		setError('');
		setLoading(true);
		try {
			const { data } = await axiosClient.get(
				`providers?${queryFilter}${query}`
			);

			setData(data[resourceKey]);
			setLoading(false);
		} catch (error) {
			console.error('getProviders error => ', error);
			setLoading(false);
		}
	};

	const filterProvider = (value) => {
		const q = value.toLowerCase().trim();
		if (Number(q) > 0) {
			setQueryFilter('filter[cactus_id][ilike]=');
		} else {
			setQueryFilter('filter[name_formatted][ilike]=');
		}
		setQuery(q);
	};

	useEffect(() => {
		if (query != 'undefined') {
			getProviders();
		}
	}, [query]);

	// submit function to save changes
	const handleSubmitNewProvider = async (payload) => {
		let result = { successful: false };
		setAddNewLoading(true);
		if (payload) {
			try {
				const { data } = await axiosClient.post('/providers', payload);
				setError(false);
				result = { ...data, successful: true };
			} catch (error) {
				if (error.response.data.errors) {
					let msg = '';
					error.response.data.errors.forEach((err) => {
						msg += err.path + ' ' + err.message + '\n';
					});
					setErrorMsg(msg);
				}
				setError(true);
			}
			setAddNewLoading(false);
			return result;
		}
	};

	const resetAddNewProvider = () => {
		setNameFirstValue('');
		setNameFirstError(false);
		setNameLastValue('');
		setNameLastError(false);
		setAddNewProvider(false);
	};

	const handleClose = () => {
		errorAnimate();
		setTimeout(() => {
			setError(false);
		}, 450);
	};

	return (
		<div
			className={`${componentName} container datagrid-home`}
			data-testid={componentName}
		>
			{!addNewProvider && (
				<div className='home-top'>
					<div className='search' data-testid='provider-search'>
						<Heading level={2}>Providers</Heading>
						<div className='search-bar'>
							<DebouncedSearchInput
								value={query}
								onChange={(value) => filterProvider(value)}
								type='text'
								placeholder='Search for a provider by name or physician ID'
								data-testid='global-filter-input'
							/>
							<Button onClick={() => filterProvider(query)}>
								<img src={SearchIcon} alt='magnifying icon' />
							</Button>
						</div>
					</div>
					<Button
						className={'add-new-provider-btn'}
						data-testid='add-new-provider-btn'
						onClick={() => {
							setAddNewProvider(true);
						}}
					>
						+ Add new provider
					</Button>
				</div>
			)}

			{!!addNewProvider && (
				<AddNewProvider
					nameFirstOnChange={(event) => {
						setNameFirstValue(event.target.value);
						setNameFirstError(event.target.value.trim() === '');
					}}
					nameFirstValue={nameFirstValue}
					nameFirstError={nameFirstError}
					nameFirstErrorText={'This field is required.'}
					// last name
					nameLastOnChange={(event) => {
						setNameLastValue(event.target.value);
						setNameLastError(event.target.value.trim() === '');
					}}
					nameLastValue={nameLastValue}
					nameLastError={nameLastError}
					nameLastErrorText={'This field is required.'}
					submitCancel={() => resetAddNewProvider()}
					loading={addNewLoading}
					error={!!error}
					errorMsg={errorMsg}
					errorRef={errorRef}
					// handle save and cancel
					submitSave={async () => {
						if (nameFirstValue.trim() === '' || nameLastValue.trim() === '') {
							setNameFirstError(nameFirstValue.trim() === '');
							setNameLastError(nameLastValue.trim() === '');
							return;
						}

						const payload = {
							name_first: nameFirstValue,
							name_last: nameLastValue,
						};

						const addNewSubmit = await handleSubmitNewProvider(payload);
						if (addNewSubmit.successful) {
							setToggleBasicInformation(true);
							navigate(`${addNewSubmit.id}`);
						}
					}}
					handleClose={() => resetAddNewProvider()}
				/>
			)}

			{!!error && (
				<Error
					errorMsg='There was an error retrieving the provider data. Please try again.'
					handleClose={handleClose}
					errorRef={errorRef}
				/>
			)}
			{!!loading && <Loading />}

			{!loading && (
				<Animation typeOfAnimation='fade-in'>
					<TableGrid
						title={gridTitle}
						data={data}
						cols={cols}
						sortColumnId={'name_formatted'}
						filterColumns={true}
					/>
				</Animation>
			)}
		</div>
	);
};

Home.propTypes = {
	/**
	 * Define component name
	 */
	componentName: string,
};

Home.defaultProps = {
	componentName: 'home',
};

export default Home;
