/**
 * File containing REPRESENTATION of Table, tr, and td html elements as individual components
 * @function
 */

import React from 'react';
import { node } from 'prop-types';

const TrCol = ({ children }) => {
	return (
		<div data-testid='tr-col' className='tr is-col'>
			{children}
		</div>
	);
};

TrCol.propTypes = {
	/**
	 * Pass a node as a children to element
	 */
	children: node.isRequired,
};
export default TrCol;
