/**
 * File to render root App
 * This file should NOT contain any logic
 *
 * @function
 */

import React from 'react';
import ReactDOM from 'react-dom/client';
import SecureWrapper from './containers/SecureWrapper';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<SecureWrapper />
	</React.StrictMode>
);

