export const dataModel = {
  providers: {
    title: 'providers',
    key: 'providers',
    map: [
      {
        key: "name_formatted",
        label: "Name"
      },
      {
        key: "cactus_id",
        label: "Physician ID"
      },
      {
        key: "service_status",
        label: "Svc. Status"
      },
      {
        key: "epic_provider_id",
        label: "Epic ID"
      },
      {
        key: "is_profile_published",
        label: "Display online"
      },
      {
        key: "publication_status",
        label: "Pub. Status"
      }
    ]
  },
  places: {
    title: 'places',
    key: 'places',
    map: [
      {
        key: "id",
        label: "ID"
      },
      {
        key: "epic_department_id",
        label: "Epic dept ID"
      },
      {
        key: "name",
        label: "Name",
        required: true
      },
      {
        key: "slug",
        label: "Slug"
      },
      {
        key: "formatted_address",
        label: "Address"
      },
      {
        key: "place_type_name",
        label: "Place type"
      }
    ]
  },
  interests: {
    title: "areas of focus",
    key: "interests",
    map: [
      {
        key: "id",
        label: "ID",
      },
      {
        key: "name",
        label: "Name",
        required: true
      },
      {
        key: "is_public",
        label: "Publish public",
        type: "checkbox",
        editOnly: true
      },
      {
        key: "specialties",
        label: "Specialties",
        type: "map",
        map: "specialties",
        field_name: "specialty_ids",
        editOnly: true
      },
      {
        key: "url",
        label: "Relative URL",
      }
    ],
  },

  recognitions: {
    title: "recognitions",
    key: "recognitions",
    map: [
      {
        key: "id",
        label: "ID",
      },
      {
        key: "name",
        label: "Name",
        required: true
      },
      {
        key: "url",
        label: "Relative URL",
      },
      {
        key: "image_url",
        label: "Image URL",
      },
    ],
  },

  specialties: {
    title: "specialties",
    key: "specialties",
    map: [
      {
        key: "id",
        label: "ID",
      },
      {
        key: "name",
        label: "Name",
        required: true
      },
      {
        key: "url",
        label: "Relative URL",
      },
      {
        key: "path",
        label: "Parent specialty",
        type: "path",
        map: "specialties",
        editOnly: true
      },
      {
        key: "is_referable",
        label: "Is referable",
        type: "checkbox",
      },
      {
        key: "interests",
        label: "Interests",
        type: "map",
        map: "interests",
        hide: true
      }
    ],
  },

  schools: {
    title: "schools",
    key: "schools",
    map: [
      {
        key: "id",
        label: "ID",
      },
      {
        key: "name",
        label: "Name",
        required: true
      },
    ],
  },

  degrees: {
    title: "degrees",
    key: "degrees",
    map: [
      {
        key: "id",
        label: "ID",
      },
      {
        key: "name",
        label: "Name",
        required: true
      },
      {
        key: "name_generalized",
        label: "Name generalized",
      },
      {
        key: "abbreviation",
        label: "Abbreviation",
        required: true
      },
      {
        key: "sort_rank",
        label: "Sort order",
        type: "number",
        required: true
      },
    ],
  },

  "medical-groups": {
    title: "medical groups",
    key: "medical_groups",
    map: [
      {
        key: "id",
        label: "ID",
      },
      {
        key: "name",
        label: "Name",
        required: true
      },
      {
        key: "name_short",
        label: "Abbreviation",
      },
      {
        key: "url",
        label: "Relative URL",
      },
      {
        key: "publication_status",
        label: "Status",
        type: "list",
        valueList: ["Unpublished", "Published"],
      },
    ],
  },
  "insurance-lists": {
    title: "insurance lists",
    key: "insurance_lists",
    map: [
      {
        key: "id",
        label: "ID"
      },
      {
        key: "name",
        label: "Name",
        required: true
      },
      {
        key: "insurance-lists",
        label: "Start with another list (optional):",
        type: "map",
        map: "insurance-lists",
        editOnly: true
      },
      {
        key: "insurance_plans",
        label: "Insurance Plans",
        editOnly: true,
        type: "checkbox-list",
        map: "insurance-plans",
      }
    ]
  },
  "insurance-plans": {
    title: "insurance plans",
    key: "insurance_plans",
    map: [
      {
        key: "id",
        label: "ID"
      },
      {
        key: "name",
        label: "Name",
        required: true
      },
      {
        key: "insurer_id",
        label: "Insurer",
        type: "select",
        map: "insurers",
        editOnly: true
      },
      {
        key: "insurer_name",
        label: "Insurer name",
        gridOnly: true
      },
      {
        key: "insurance_plan_type_id",
        label: "Insurance plan type",
        type: "select",
        map: "insurance-plan-types",
        editOnly: true
      },
      {
        key: "insurance_plan_type_name",
        label: "Insurance plan type name",
        gridOnly: true
      },
    ]
  },
  "insurance-plan-types": {
    title: "insurance plan types",
    key: "insurance_plan_types",
    map: [
      {
        key: "id",
        label: "ID",
      },
      {
        key: "name",
        label: "Name",
        required: true
      },
    ],
  },
  "insurers": {
    title: "insurers",
    key: "insurers",
    map: [
      {
        key: "id",
        label: "ID",
      },
      {
        key: "name",
        label: "Name",
        required: true
      },
      {
        key: "url",
        label: "URL",
      }
    ],
  },
  "place-types": {
    title: "Place types",
    key: "place_types",
    map: [
      {
        key: "id",
        label: "ID",
      },
      {
        key: "name",
        label: "Label",
        required: true
      },
      {
        key: "name_plural",
        label: "Plural label",
        required: true
      },
      {
        key: "url",
        label: "Relative URL",
      },
      {
        key: "description",
        label: "Description",
        required: true
      },
      {
        key: "path",
        label: "Parent place type (if applicable)",
        type: "path",
        map: "place-types"
      }
    ]
  },
  "tags": {
    title: "Tags",
    key: "tags",
    map: [
      {
        key: "id",
        label: "ID",
      },
      {
        key: "name",
        label: "Label",
        required: true
      },
      {
        key: "name_plural",
        label: "Plural label",
        required: true
      },
      {
        key: "url",
        label: "Relative URL",
      },
      {
        key: "description",
        label: "Description",
        required: true
      },
      {
        key: "type",
        label: "Type",
        type: "list",
        required: true,
        valueList: [ "Category", "Feature", "Region", "Service", "Yext Category" ]
      }
    ]
  },
  "hospitals": {
    title: "hospital",
    key: "hospitals",
    map: [
      {
        key: "id",
        label: "ID",
      },
      {
        key: "name",
        label: "Name",
        required: true
      },
      {
        key: "url",
        label: "Relative URL",
      }
    ]
  }
};