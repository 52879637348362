/**
 * File to contain Pencil SVG
 * @function
 */

import React from "react";
import { string } from "prop-types";
function Pencil() {
  return (
    <svg width="15px" height="15px" viewBox="0 0 24 24" className="pencil">
      <g
        className="wrapper"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <g className="pencil" fill="#18808c">
          <path
            d="M3,18 L14.9997108,6 L18,9 L6,21 L3,21 L3,18 Z M16,5 L17.9997108,3 L21,6 L18.9989741,8.00102587 L16,5 Z"
            className="shape"
          />
        </g>
      </g>
    </svg>
  );
}

Pencil.propTypes = {
  /**
   * Define component name
   */
  componentName: string,
};

Pencil.defaultProps = {
  componentName: "pencil",
};

export default Pencil;
