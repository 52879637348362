/**
 * File containing REPRESENTATION of Table, tr, and td html elements as individual components
 * @function
 */

import React from 'react';
import { node, bool } from 'prop-types';

function Td({ children, toFull, isTitle, setToArial }) {
	// Choose if the paragraph element spans the full div based on mock up
	const renderFull = !!toFull ? `td full` : 'td not-full';
	const renderSetArial = !!setToArial && 'set-to-arial';

	return (
		<p
			data-testid='td'
			className={`${renderFull} ${renderSetArial}`}
			style={{ fontWeight: isTitle && '700', lineHeight: isTitle && '35px' }}
		>
			{children}
		</p>
	);
}

Td.propTypes = {
	/**
	 * Pass a node as a children to element
	 */
	children: node,

	/**
	 * Condition to render specific font-weight based on role
	 */
	isTitle: bool,

	/**
	 * Condition to render specific width
	 */
	toFull: bool,
	/**
	 * Condition to set font-family to arial
	 */
	setToArial: bool,
};

export default Td;
