/**
 * File to contain Loader SVG
 * @function
 */
import React from 'react';
import { string } from 'prop-types';

const Loader = ({ componentName, fill, width, height }) => {
	return (
		<svg
			className={componentName}
			version='1.1'
			x='0px'
			y='0px'
			viewBox='0 0 100 100'
			enableBackground='new 0 0 0 0'
			width={width}
			height={height}
		>
			<path
				className='path'
				fill={fill}
				d='M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50'
			>
				<animateTransform
					attributeName='transform'
					attributeType='XML'
					type='rotate'
					dur='1s'
					from='0 50 50'
					to='360 50 50'
					repeatCount='indefinite'
				/>
			</path>
		</svg>
	);
};

Loader.propTypes = {
	/**
	 * Define component name
	 */
	componentName: string,
	/**
	 * Define fill color
	 */
	fill: string,
	/**
	 * Define width
	 */
	width: string,
	/**
	 * Define height
	 */
	height: string,
};

Loader.defaultProps = {
	componentName: 'loader',
};
export default Loader;
