/**
 * File to contain Error component
 * @function
 */

import React from 'react';
import { string, func, object } from 'prop-types';
import Close from '../assets/Close';
import Animation from './Animation';

const Error = ({ componentName, errorMsg, handleClose, errorRef }) => {
	return (
		<Animation typeOfAnimation='vibrate'>
			<button
				className={componentName}
				data-testid={`${componentName}-btn`}
				onClick={handleClose}
				ref={errorRef}
				style={{ animationDelay: 0 }}
			>
				<p className='paragraph' data-testid={`${componentName}-paragraph`}>
					{errorMsg}
				</p>
				<Close stroke='#ba4534' />
			</button>
		</Animation>
	);
};

Error.propTypes = {
	/**
	 * Define component name
	 */
	componentName: string,
	/**
	 * handle functionality to close error message
	 */
	handleClose: func.isRequired,
	/**
	 * Define error message
	 */
	errorMsg: string.isRequired,
	/**
	 * Ref for animation
	 */
	errorRef: object.isRequired,
};

Error.defaultProps = {
	componentName: 'error',
};

export default Error;
