/**
 * File to contain CardFooter component
 * @function
 */

import React from 'react';
import { string, func, bool } from 'prop-types';
import Button from 'sharp-component-library/build/Button';

const CardFooter = ({
	componentName,
	submitSave,
	textSave,
	submitCancel,
	saveDisabled,
}) => {
	return (
		<div className={componentName} data-testid={componentName}>
			<Button disabled={saveDisabled} variant='primary' onClick={submitSave}>
				{textSave}
			</Button>
			<Button variant='transparent' onClick={submitCancel}>
				Cancel
			</Button>
		</div>
	);
};

CardFooter.propTypes = {
	/**
	 * Define component name
	 */
	componentName: string,
	/**
	 * Function for submitting save
	 */
	submitSave: func.isRequired,
	/**
	 * Function for submitting cancel
	 */
	submitCancel: func.isRequired,
	/**
	 * Choose to disable button
	 */
	saveDisabled: bool,
};

CardFooter.defaultProps = {
	componentName: 'card-footer',
	textSave: 'Save',
	saveDisabled: false,
};

export default CardFooter;
