/**
 * File to render application
 * This file should only contain basic logic and wrappers
 * (e.g. state management wrapper, browser routing wrapper)
 *
 * Any mutating of data should be handled in the
 * child node(s) in the container folder.
 *
 * @function
 */
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from './utils/context';
import Home from './containers/find-a-doctor/Home';
import './styles/index.css';
import './styles/app.scss';
import { string } from 'prop-types';
import RouterWrapper from './containers/RouterWrapper';

const queryClient = new QueryClient();

const App = ({ componentName }) => {
	return (
		<QueryClientProvider client={queryClient}>
			<Provider>
				<RouterWrapper>
					<div className={componentName} data-testid={componentName}>
						<Home />
					</div>
				</RouterWrapper>
			</Provider>
		</QueryClientProvider>
	);
};

App.propTypes = {
	componentName: string,
	token: string
};

App.defaultProps = {
	componentName: 'app',
	token: ''
};

export default App;
