/**
 * File containing REPRESENTATION of Table, tr, and td html elements as individual components
 * @function
 */

import React from 'react';
import { string, node, bool } from 'prop-types';

const Table = ({ componentName, children, isEditing }) => {
	return (
		<div
			className={isEditing ? `${componentName} is-editing` : componentName}
			data-testid={componentName}
		>
			{children}
		</div>
	);
};

Table.propTypes = {
	/**
	 * Define component name
	 */
	componentName: string,
	/**
	 * Pass a node as a children to element
	 */
	children: node.isRequired,
	/**
	 * Decide if element should display flex or inline-flex
	 */
	toFull: string,
	/**
	 * Decide if element should be flex-direction row or column
	 */
	isRow: string,
	/**
	 * Decide if table element is toggled to editing mode
	 */
	isEditing: bool,
};

Table.defaultProps = {
	componentName: 'is-table',
	isEditing: false,
};

export default Table;
