// CONFIGS
const configs = () => {
	switch (window.location.host) {
		case 'providers-admin.sharp.com':
			return JSON.parse(JSON.stringify(require('./assets/configs/config.prod.json')));
		case 'providers-admin.aws-nonprod.sharp.com':
			return JSON.parse(JSON.stringify(require('./assets/configs/config.test.json')));
		default:
			return JSON.parse(JSON.stringify(require('./assets/configs/config.local.json')));		 
	}
};

export default configs;