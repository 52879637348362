/**
 * File to contain Loading component
 * @function
 */

import React from 'react';
import { string } from 'prop-types';

import Loader from '../assets/Loader';

const Loading = ({ componentName, defaultText }) => {
	return (
		<div className={componentName} data-testid={componentName}>
			<Loader width='50px' height='50px' fill='#333' />

			<p className='paragraph'>{defaultText}</p>
		</div>
	);
};

Loading.propTypes = {
	/**
	 * Define component name
	 */
	componentName: string,
	/**
	 * Define loading text
	 */
	defaultText: string,
};

Loading.defaultProps = {
	componentName: 'loading',
	defaultText: 'Loading',
};

export default Loading;
