import React, { createContext, useState } from 'react';
import { useBoolean, useHandleInput } from '../providers/formHandlers';
import useAnimate from './useAnimate';
import configs from '../Configs';

const Home = React.lazy(() => import('../containers/find-a-doctor/Home'));
const LocationsHome = React.lazy(() =>
	import('../containers/locations/LocationsHome')
);

export const AppContext = createContext();

/**
 * File to contain Provider function, that wraps the entire application.
 * This file is also to declare any global state and functions.
 * @function
 * @object
 * @array
 */

export const Provider = (props) => {
	// set configs
	const appConfigs = configs();
	// set user state
	const [user, setUser] = useState({});
	const [location, setLocation] = useState({});
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	// initialize animation hooks
	const [errorRef, errorAnimate] = useAnimate('fade-out');
	const [successRef, successAnimate] = useAnimate('fade-out');
	const [promptRef, promptAnimate] = useAnimate('flip-out');

	// visible card state for error, success, and loading
	const [visibleCard, setVisibleCard] = useState({
		error: '',
		success: '',
		loading: '',
	});
	// set application states that are reusable globals to avoid prop drilling

	// INTRO CARD INFO
	const [providerData, setProviderData] = useState({});
	const [toggleEditAlert, setToggleEditAlert] = useBoolean();
	const alertMessage = useHandleInput('');
	// BASIC INFORMATION STATE
	const [toggleBasicInformation, setToggleBasicInformation] = useBoolean();
	const [displayNameValue, setDisplayNameValue] = useState('');
	const [displayNameOnFocus, setDisplayNameOnFocus] = useBoolean();

	const [nameFirstValue, setNameFirstValue] = useState('');
	const [nameFirstOnFocus, setNameFirstOnFocus] = useBoolean();
	const [nameMiddleValue, setNameMiddleValue] = useState('');
	const [nameMiddleOnFocus, setNameMiddleOnFocus] = useBoolean();
	const [nameLastValue, setNameLastValue] = useState('');
	const [nameLastOnFocus, setNameLastOnFocus] = useBoolean();
	const [namePronunciationValue, setNamePronunciationValue] = useState('');
	const [namePronunciationOnFocus, setNamePronunciationOnFocus] = useBoolean();
	const [namePreferredValue, setNamePreferredValue] = useState('');
	const [namePreferredOnFocus, setNamePreferredOnFocus] = useBoolean();

	const [npiValue, setNpiValue] = useState(null);
	const [npiValueOnFocus, setNpiValueOnFocus] = useBoolean();
	const [californiaLicenseValue, setCaliforniaLicenseValue] = useState(null);
	const [californiaLicenseValueOnFocus, setCaliforniaLicenseValueOnFocus] =
		useBoolean();
	const [epicProviderId, setEpicProviderId] = useState('');
	const [epicProviderSchedulingSettings, setEpicProviderSchedulingSettings] = useState(null);

	const [physicianIdValue, setPhysicianIdValue] = useState(null);
	const [physicianIdNumberError, setPhysicianIdNumberError] = useState(false);
	const [physicianIdOnFocus, setPhysicianIdOnFocus] = useBoolean();
	const [dateOfBirthValue, setDateOfBirthValue] = useState(null);
	const [dateOfBirthValueOnFocus, setDateOfBirthValueOnFocus] = useBoolean();
	const [dateOfBirthError, setDateOfBirthError] = useState(false);
	const [dateOfBirthMonth, setDateOfBirthMonth] = useState('');
	const [dateOfBirthDay, setDateOfBirthDay] = useState('');
	const [dateOfBirthYear, setDateOfBirthYear] = useState('');

	const [pronounValue, setPronounValue] = useState('');
	const [pronounOnFocus, setPronounOnFocus] = useBoolean();
	const [genderValue, setGenderValue] = useState('');
	const [genderValueOnFocus, setGenderValueOnFocus] = useBoolean();
	const [checkedGender, setCheckedGender] = useState(false);
	const [ageValue, setAgeValue] = useState(null);
	const [checkedAge, setCheckedAge] = useState(false);
	const [yearStartedPracticeValue, setYearStartedPracticeValue] =
		useState(null);
	const [yearStartedPracticeOnFocus, setYearStartedPracticeOnFocus] =
		useBoolean();
	const [startedPracticeError, setStartedPracticeError] = useState(false);
	const [yearStartedWithSharpValue, setYearStartedWithSharpValue] =
		useState(null);
	const [yearStartedWithSharpOnFocus, setYearStartedWithSharpOnFocus] =
		useBoolean();
	const [startedWithSharpError, setStartedWithSharpError] = useState(false);
	const [checkedYearStarted, setCheckedYearStarted] = useState(false);
	const [websiteUrl, setWebsiteUrl] = useState('');
	const [websiteUrlError, setWebsiteUrlError] = useState(false);
	const [partOfFollowMyHealth, setPartOfFollowMyHealth] = useState(false);
	const [partOfEpic, setPartOfEpic] = useState(false);
	const [provEmail, setProvEmail] = useState(null);
	const [provEmailError, setProvEmailError] = useState(false);

	const [cellPhone, setCellPhone] = useState('');
	const [cellPhoneError, setCellPhoneError] = useState(false);

	// BASIC INFORMATION STATE END

	// Availability STATE
	const [toggleAvailability, setToggleAvailability] = useBoolean();
	const [serviceStatus, setServiceStatus] = useState('');
	const [serviceStatusOnFocus, setServiceStatusOnFocus] = useBoolean();
	const [panelStatus, setPanelStatus] = useState('');
	const [panelStatusOnFocus, setPanelStatusOnFocus] = useBoolean();
	const [panelRestrictionDescription, setPanelRestrictionDescription] =
		useState([]);
	const [
		panelRestrictionDescriptionOnFocus,
		setPanelRestrictionDescriptionOnFocus,
	] = useBoolean();
	const [panelRestrictionCustom, setPanelRestrictionCustom] = useState('');
	const [panelRestrictionCustomOnFocus, setPanelRestrictionCustomOnFocus] =
		useBoolean();
	const [nonReferableReason, setNonReferableReason] = useState('');
	const [nonReferableReasonFocus, setNonReferableReasonFocus] = useBoolean();
	const [checkedOnlineScheduling, setCheckedOnlineScheduling] = useState(false);
	const [thirdPartySchedulingUrl, setThirdPartySchedulingUrl] = useState('');
	const [thirdPartySchedulingUrlFocus, setThirdPartySchedulingUrlFocus] =
		useBoolean();

	const [thirdPartySchedulingUrlError, setThirdPartySchedulingUrlError] =
		useState(false);

	const [checkedVirtualVisit, setCheckedVirtualVisit] = useState(false);

	// Location STATE
	const [toggleLocation, setToggleLocation] = useBoolean();
	const [toggleLocationBasicInfo, setToggleLocationBasicInfo] = useBoolean();
	const [locationParentPlace, setLocationParentPlace] = useState(null);
	const [locationParentPlaceValue, setLocationParentPlaceValue] = useState('');
	const [locationName, setLocationName] = useState('');
	const [locationShortName, setLocationShortName] = useState('');
	const [locationSlug, setLocationSlug] = useState('');
	const [locationSlugError, setLocationSlugError] = useState(false);
	const [locationDescription, setLocationDescription] = useState('');
	const [allPlaceTypes, setAllPlaceTypes] = useState([]);
	const [locationPlaceType, setLocationPlaceType] = useState(null);
	const [allMedicalGroups, setAllMedicalGroups] = useState([]);
	const [locationMedicalGroups, setLocationMedicalGroups] = useState([]);
	const [allHospitals, setAllHospitals] = useState([]);
	const [locationHospitals, setLocationHospitals] = useState([]);
	const [locationAddress, setLocationAddress] = useState('');
	const [locationSecondaryAddress, setLocationSecondaryAddress] = useState('');
	const [locationSublocality, setLocationSublocality] = useState('');
	const [locationLatitude, setLocationLatitude] = useState('');
	const [locationLatitudeError, setLocationLatitudeError] = useState(false);
	const [locationLongitude, setLocationLongitude] = useState('');
	const [locationLongitudeError, setLocationLongitudeError] = useState(false);
	const [locationPhone, setLocationPhone] = useState('');
	const [locationPhoneError, setLocationPhoneError] = useState(false);
	const [locationFax, setLocationFax] = useState('');
	const [locationFaxError, setLocationFaxError] = useState(false);
	const [locationIntersection, setLocationIntersection] = useState('');
	const [locationParkingInfo, setLocationParkingInfo] = useState('');
	const [locationInheritAddress, setLocationInheritAddress] = useState(false);
	const [locationInheritSecondaryAddress, setLocationInheritSecondaryAddress] =
		useState(false);
	const [locationInheritContact, setLocationInheritContact] = useState(false);
	const [locationInheritGeo, setLocationInheritGeo] = useState(false);
	const [locationInheritParking, setLocationInheritParking] = useState(false);
	const [toggleLocationHours, setToggleLocationHours] = useBoolean();
	const [locationSchedule, setLocationSchedule] = useState([]);
	const [locationInheritSchedule, setLocationInheritSchedule] = useState(false);
	const [locationScheduleNote, setLocationScheduleNote] = useState('');
	const [locationScheduleErrors, setLocationScheduleErrors] = useState(false);
	const [locationScheduleExceptions, setLocationScheduleExceptions] = useState([]);
	const [locationScheduleExceptionsErrors, setLocationScheduleExceptionsErrors] = useState(false);
	const [locationExtUrl, setLocationExtUrl] = useState('');

	// Location: Tags states
	const [toggleLocationTags, setToggleLocationTags] = useBoolean();
	const [placeTags, setPlaceTags] = useState([]);
	const [tagsResource, setTagsResource] = useState([]);

	// Location: Scheduling states
	const [toggleLocationScheduling, setToggleLocationScheduling] = useBoolean();
	const [scheduleStatus, setScheduleStatus] = useState('');
	const [scheduleType, setScheduleType] = useState('');
	const [scheduleCtaUrl, setScheduleCtaUrl] = useState('');
	const [scheduleCtaLabel, setScheduleCtaLabel] = useState('');
	const [scheduleTitle, setScheduleTitle] = useState('');
	const [scheduleDescription, setScheduleDescription] = useState('');
	const [scheduleDowntimeMessage, setScheduleDowntimeMessage] = useState('');
	const [scheduleParams, setScheduleParams] = useState('');
	const [schedUrlError, setSchedUrlError] = useState(false);

	// Location Yext
	const [toggleLocationYext, setToggleLocationYext] = useBoolean();
	const [locationYextStatus, setLocationYextStatus] = useState('');
	const [locationYextBusinessName, setLocationYextBusinessName] = useState('');
	const [locationYextBusinessDescription, setLocationYextBusinessDescription] = useState('');
	const [locationYextFeaturedMessage, setLocationYextFeaturedMessage] = useState('');
	const [locationYextFeaturedMessageURL, setLocationYextFeaturedMessageURL] = useState('');
	const [locationYextFeaturedMessageURLError, setLocationYextFeaturedMessageURLError] = useState(false);
	
	// Image error
	const [imageError, setImageError] = useState(false);

	// Areas of focus STATE
	const [toggleAreasOfFocus, setToggleAreasOfFocus] = useBoolean();
	const [interestsList, setInterestsList] = useState();
	const [provInterest, setProvInterest] = useState();

	// Insurance STATE
	const [toggleInsurance, setToggleInsurance] = useBoolean();
	const [insuranceList, setInsuranceList] = useState([]);
	const [provInsuranceList, setProvInsuranceList] = useState([]);
	const [insurancePreviousItems, setInsurancePreviousItems] = useState([]);
	const [triggerInsurancePrompt, setTriggerInsurancePrompt] = useState(false);
	const [activeInsuranceId, setActiveInsuranceId] = useState(null);

	// Ratings and Reviews STATE
	const [toggleRatingsAndReviews, setToggleRatingsAndReviews] = useBoolean();
	const [checkPublishReviews, setCheckPublishReviews] = useState(false);

	// Biography STATE
	const [toggleBiography, setToggleBiography] = useBoolean();
	const [biography, setBiography] = useState(null);

	// Special Recognitions STATE
	const [toggleSpecialRecognitions, setToggleSpecialRecognitions] =
		useBoolean();

	// Profile Picture STATE
	const [toggleProfilePicture, setToggleProfilePicture] = useBoolean();
	const [profileImageUrl, setProfileImageUrl] = useState('');
	const [preview, setPreview] = useState('');

	// Location Picture STATE
	const [toggleLocationPicture, setToggleLocationPicture] = useBoolean();
	const [locationImageUrl, setLocationImageUrl] = useState('');

	// Provider Locations STATE
	const [providerLocations, setProviderLocations] = useState([]);

	// Video STATE
	const [toggleVideo, setToggleVideo] = useBoolean();
	const videoUrl = useHandleInput([]);

	// Specialty STATE
	const [toggleSpecialty, setToggleSpecialty] = useBoolean();

	// Educations STATE
	const [toggleEducations, setToggleEducations] = useBoolean();
	const [providerEducations, setProviderEducations] = useState([]);
	const [schoolsList, setSchoolsList] = useState([]);

	// Yext STATE
	const [toggleYext, setToggleYext] = useBoolean();

	// sharp.com display status STATE
	const [toggleAtAGlance, setToggleAtAGlance] = useBoolean();
	const [statusValue, setStatusValue] = useState(null);
	const [statusValueOnFocus, setStatusValueOnFocus] = useBoolean();
	const [publicationOverrideValue, setPublicationOverrideValue] =
		useState(null);
	const [publicationOverrideValueOnFocus, setPublicationOverrideValueOnFocus] =
		useBoolean();
	const [slugValue, setSlugValue] = useState(null);
	const [slugValueOnFocus, setSlugValueOnFocus] = useBoolean();

	// STATE for tracking any open cards in provider
	const [toggleInProgressEdit, setToggleInProgressEdit] = useState(false);
	// STATE to trigger prompt for any open cards in provider
	const [confirmState, setConfirmState] = useState(false);
	const [cancelState, setCancelState] = useState(false);
	const [hasSelectedCancel, setHasSelectedCancel] = useState(false);

	const [areaOfCrud, setAreaOfCrud] = useState('');

	// STATE for tracking languages
	const [languagesList, setLanguagesList] = useState([]);
	const [languagesPreviousItems, setLanguagesPreviousItems] = useState([]);
	const [submitLanguage, setSubmitLanguage] = useState();
	const [triggerLanguagePrompt, setTriggerLanguagePrompt] = useState(false);
	const [activeLanguageId, setActiveLanguageId] = useState(null);
	const [newLanguage, setNewLanguage] = useState();

	// STATE for tracking degrees
	const [degreesList, setDegreesList] = useState([]);
	const [degreesPreviousItems, setDegreesPreviousItems] = useState([]);
	const [submitDegree, setSubmitDegree] = useState();
	const [triggerDegreePrompt, setTriggerDegreePrompt] = useState(false);
	const [activeDegreeId, setActiveDegreeId] = useState(null);
	const [newDegree, setNewDegree] = useState();

	// STATE for tracking specialties
	const [specialtiesList, setSpecialtiesList] = useState([]);
	const [specialtiesPreviousItems, setSpecialtiesPreviousItems] = useState([]);
	const [triggerSpecialtyPrompt, setTriggerSpecialtyPrompt] = useState(false);
	const [activeSpecialtyId, setActiveSpecialtyId] = useState(null);

	// STATE for tracking care partners
	const [carePartnersList, setCarePartnersList] = useState([]);

	const [submitCarePartner, setSubmitCarePartner] = useState();
	const [triggerCarePartnerPrompt, setTriggerCarePartnerPrompt] =
		useState(false);
	const [activeCarePartnerId, setActiveCarePartnerId] = useState(null);

	// STATE for tracking crud component
	const [triggerCrudPrompt, setTriggerCrudPrompt] = useState(false);
	const [toggleCrudEditing, setToggleCrudEditing] = useState(false);

	// STATE for tracking special recognitions
	const [
		specialRecognitionsPreviousItems,
		setSpecialRecognitionsPreviousItems,
	] = useState([]);
	const [specialRecognitionsList, setSpecialRecognitionsList] = useState([]);
	const [currentRecognitionName, setCurrentRecognitionName] = useState('');
	const [currentRecognitionId, setCurrentRecognitionId] = useState('');
	const [numberOfRecognitions, setNumberOfRecognitions] = useState('');
	const [recognitionsError, setRecognitionsError] = useState(false);
	// Affiliations STATE
	const [toggleAffiliations, setToggleAffiliations] = useBoolean();
	const [provHospitalList, setProvHospitalList] = useState([]);
	const [provMedGroupList, setProvMedGroupList] = useState([]);
	const [medGroupsList, setMedGroupsList] = useState([]);
	const [hospitalsList, setHospitalsList] = useState([]);

	const [yextPubStatus, setYextPubStatus] = useState('');
	const [yextBusinessName, setYextBusinessName] = useState('');
	const [yextBusinessDesc, setYextBusinessDesc] = useState('');



	const mainNav = [
		{
			parentPath: '/find-a-doctor',
			path: '/find-a-doctor/providers',
			name: 'Find a doctor',
			element: Home,
		},
		{
			parentPath: '/locations',
			path: '/locations/locations',
			name: 'Locations',
			element: LocationsHome,
		},
		{
			parentPath: '/insurance',
			path: '/insurance/insurance-lists',
			name: 'Insurance',
			element: LocationsHome,
		},
		{
			parentPath: '/entities',
			path: '/entities/hospitals',
			name: 'Entities',
		},
	];

	const fadSubNav = [
		{ path: '/find-a-doctor/providers', name: 'Providers', element: Home },
		{
			path: '/find-a-doctor/specialties',
			name: 'Specialties',
		},
		{
			path: '/find-a-doctor/interests',
			name: 'Areas of focus',
		},
		{
			path: '/find-a-doctor/recognitions',
			name: 'Recognitions',
		},
		{
			path: '/find-a-doctor/schools',
			name: 'Schools',
		},
		{
			path: '/find-a-doctor/degrees',
			name: 'Degrees',
		},
	];
	const locationSubNav = [
		{
			path: '/locations/locations',
			name: 'Places',
			element: LocationsHome,
		},
		{
			path: '/locations/place-types',
			name: 'Place types',
		},
		{
			path: '/locations/tags',
			name: 'Tags',
		},
	];

	const InsuranceSubNav = [
		{
			path: '/insurance/insurance-lists',
			name: 'List',
		},
		{
			path: '/insurance/insurers',
			name: 'Insurers',
		},
		{
			path: '/insurance/insurance-plans',
			name: 'Plan',
		},
		{
			path: '/insurance/insurance-plan-types',
			name: 'Plan types',
		},
	];

	const entitiesSubNav = [
		{
			path: '/entities/hospitals',
			name: 'Hospitals',
		},
		{
			path: '/entities/medical-groups',
			name: 'Medical groups',
		},
	];

	return (
		<AppContext.Provider
			// add items from destructed state(s)
			value={{
				appConfigs,
				user,
				setUser,
				location,
				setLocation,
				providerData,
				setProviderData,
				errorRef,
				errorAnimate,
				successRef,
				successAnimate,
				promptRef,
				promptAnimate,
				error,
				setError,
				loading,
				setLoading,
				success,
				setSuccess,
				visibleCard,
				setVisibleCard,
				toggleEditAlert,
				setToggleEditAlert,
				alertMessage,
				displayNameValue,
				setDisplayNameValue,
				displayNameOnFocus,
				setDisplayNameOnFocus,
				nameFirstValue,
				setNameFirstValue,
				nameFirstOnFocus,
				setNameFirstOnFocus,
				nameMiddleValue,
				setNameMiddleValue,
				nameMiddleOnFocus,
				setNameMiddleOnFocus,
				nameLastValue,
				setNameLastValue,
				nameLastOnFocus,
				setNameLastOnFocus,
				namePreferredValue,
				setNamePreferredValue,
				namePreferredOnFocus,
				setNamePreferredOnFocus,
				namePronunciationValue,
				setNamePronunciationValue,
				namePronunciationOnFocus,
				setNamePronunciationOnFocus,
				npiValue,
				setNpiValue,
				npiValueOnFocus,
				setNpiValueOnFocus,
				californiaLicenseValue,
				setCaliforniaLicenseValue,
				californiaLicenseValueOnFocus,
				setCaliforniaLicenseValueOnFocus,
				epicProviderId,
				setEpicProviderId,
				epicProviderSchedulingSettings,
				setEpicProviderSchedulingSettings,
				degreesList,
				setDegreesList,
				degreesPreviousItems,
				setDegreesPreviousItems,
				submitDegree,
				setSubmitDegree,
				triggerDegreePrompt,
				setTriggerDegreePrompt,
				activeDegreeId,
				setActiveDegreeId,
				newDegree,
				setNewDegree,
				specialtiesList,
				setSpecialtiesList,
				specialtiesPreviousItems,
				setSpecialtiesPreviousItems,
				triggerSpecialtyPrompt,
				setTriggerSpecialtyPrompt,
				activeSpecialtyId,
				setActiveSpecialtyId,
				physicianIdValue,
				setPhysicianIdValue,
				physicianIdNumberError,
				setPhysicianIdNumberError,
				physicianIdOnFocus,
				setPhysicianIdOnFocus,
				dateOfBirthValue,
				setDateOfBirthValue,
				dateOfBirthValueOnFocus,
				setDateOfBirthValueOnFocus,
				dateOfBirthError,
				setDateOfBirthError,
				dateOfBirthMonth,
				setDateOfBirthMonth,
				dateOfBirthDay,
				setDateOfBirthDay,
				dateOfBirthYear,
				setDateOfBirthYear,
				pronounValue,
				setPronounValue,
				pronounOnFocus,
				setPronounOnFocus,
				genderValue,
				setGenderValue,
				genderValueOnFocus,
				setGenderValueOnFocus,
				checkedGender,
				setCheckedGender,
				ageValue,
				setAgeValue,
				checkedAge,
				setCheckedAge,
				yearStartedPracticeValue,
				setYearStartedPracticeValue,
				yearStartedPracticeOnFocus,
				setYearStartedPracticeOnFocus,
				startedPracticeError,
				setStartedPracticeError,
				yearStartedWithSharpValue,
				setYearStartedWithSharpValue,
				yearStartedWithSharpOnFocus,
				setYearStartedWithSharpOnFocus,
				startedWithSharpError,
				setStartedWithSharpError,
				checkedYearStarted,
				setCheckedYearStarted,
				websiteUrl,
				setWebsiteUrl,
				websiteUrlError,
				setWebsiteUrlError,
				partOfFollowMyHealth,
				setPartOfFollowMyHealth,
				partOfEpic,
				setPartOfEpic,
				provEmail,
				setProvEmail,
				provEmailError,
				setProvEmailError,
				cellPhone,
				setCellPhone,
				cellPhoneError,
				setCellPhoneError,
				toggleAvailability,
				setToggleAvailability,
				serviceStatus,
				setServiceStatus,
				serviceStatusOnFocus,
				setServiceStatusOnFocus,
				panelStatus,
				setPanelStatus,
				panelStatusOnFocus,
				setPanelStatusOnFocus,
				panelRestrictionDescription,
				setPanelRestrictionDescription,
				panelRestrictionDescriptionOnFocus,
				setPanelRestrictionDescriptionOnFocus,
				panelRestrictionCustom,
				setPanelRestrictionCustom,
				panelRestrictionCustomOnFocus,
				setPanelRestrictionCustomOnFocus,
				carePartnersList,
				setCarePartnersList,
				submitCarePartner,
				setSubmitCarePartner,
				triggerCarePartnerPrompt,
				setTriggerCarePartnerPrompt,
				activeCarePartnerId,
				setActiveCarePartnerId,
				nonReferableReason,
				setNonReferableReason,
				checkedOnlineScheduling,
				setCheckedOnlineScheduling,
				thirdPartySchedulingUrl,
				setThirdPartySchedulingUrl,
				thirdPartySchedulingUrlError,
				setThirdPartySchedulingUrlError,
				checkedVirtualVisit,
				setCheckedVirtualVisit,
				nonReferableReasonFocus,
				setNonReferableReasonFocus,
				thirdPartySchedulingUrlFocus,
				setThirdPartySchedulingUrlFocus,
				toggleBasicInformation,
				setToggleBasicInformation,
				toggleEducations,
				setToggleEducations,
				providerEducations,
				setProviderEducations,
				schoolsList,
				setSchoolsList,
				toggleLocation,
				setToggleLocation,
				toggleLocationBasicInfo,
				setToggleLocationBasicInfo,
				locationParentPlace,
				setLocationParentPlace,
				locationParentPlaceValue,
				setLocationParentPlaceValue,
				locationName,
				setLocationName,
				locationShortName,
				setLocationShortName,
				locationSlug,
				setLocationSlug,
				locationSlugError,
				setLocationSlugError,
				locationDescription,
				setLocationDescription,
				allPlaceTypes,
				setAllPlaceTypes,
				locationPlaceType,
				setLocationPlaceType,
				allMedicalGroups,
				setAllMedicalGroups,
				locationMedicalGroups,
				setLocationMedicalGroups,
				allHospitals,
				setAllHospitals,
				locationHospitals,
				setLocationHospitals,
				locationInheritAddress,
				setLocationInheritAddress,
				locationAddress,
				setLocationAddress,
				locationInheritSecondaryAddress,
				setLocationInheritSecondaryAddress,
				locationSecondaryAddress,
				setLocationSecondaryAddress,
				locationSublocality,
				setLocationSublocality,
				locationInheritContact,
				setLocationInheritContact,
				locationPhone,
				setLocationPhone,
				locationPhoneError,
				setLocationPhoneError,
				locationFax,
				setLocationFax,
				locationFaxError,
				setLocationFaxError,
				locationIntersection,
				setLocationIntersection,
				locationInheritParking,
				setLocationInheritParking,
				locationParkingInfo,
				setLocationParkingInfo,
				locationInheritGeo,
				setLocationInheritGeo,
				locationLatitude,
				setLocationLatitude,
				locationLatitudeError,
				setLocationLatitudeError,
				locationLongitude,
				setLocationLongitude,
				locationLongitudeError,
				setLocationLongitudeError,
				toggleLocationHours,
				setToggleLocationHours,
				locationSchedule,
				setLocationSchedule,
				locationInheritSchedule,
				setLocationInheritSchedule,
				locationScheduleNote,
				setLocationScheduleNote,
				locationScheduleErrors,
				setLocationScheduleErrors,
				locationScheduleExceptions,
				setLocationScheduleExceptions,
				locationScheduleExceptionsErrors,
				setLocationScheduleExceptionsErrors,
				toggleLocationYext,
				setToggleLocationYext,
				locationYextStatus,
				setLocationYextStatus,
				locationYextBusinessName,
				setLocationYextBusinessName,
				locationYextBusinessDescription,
				setLocationYextBusinessDescription,
				locationYextFeaturedMessage,
				setLocationYextFeaturedMessage,
				locationYextFeaturedMessageURL,
				setLocationYextFeaturedMessageURL,
				locationYextFeaturedMessageURLError,
				setLocationYextFeaturedMessageURLError,
				toggleAreasOfFocus,
				setToggleAreasOfFocus,
				toggleInsurance,
				setToggleInsurance,
				toggleRatingsAndReviews,
				setToggleRatingsAndReviews,
				checkPublishReviews,
				setCheckPublishReviews,
				toggleBiography,
				setToggleBiography,
				biography,
				setBiography,
				toggleSpecialRecognitions,
				setToggleSpecialRecognitions,
				toggleProfilePicture,
				setToggleProfilePicture,
				profileImageUrl,
				setProfileImageUrl,
				imageError,
				setImageError,
				preview,
				setPreview,
				toggleVideo,
				setToggleVideo,
				toggleSpecialty,
				setToggleSpecialty,
				toggleYext,
				setToggleYext,
				toggleAtAGlance,
				setToggleAtAGlance,
				statusValue,
				setStatusValue,
				statusValueOnFocus,
				setStatusValueOnFocus,
				publicationOverrideValue,
				setPublicationOverrideValue,
				publicationOverrideValueOnFocus,
				setPublicationOverrideValueOnFocus,
				slugValue,
				setSlugValue,
				slugValueOnFocus,
				setSlugValueOnFocus,
				mainNav,
				fadSubNav,
				locationSubNav,
				entitiesSubNav,
				toggleInProgressEdit,
				setToggleInProgressEdit,
				confirmState,
				setConfirmState,
				cancelState,
				setCancelState,
				hasSelectedCancel,
				setHasSelectedCancel,
				InsuranceSubNav,
				languagesList,
				setLanguagesList,
				languagesPreviousItems,
				setLanguagesPreviousItems,
				submitLanguage,
				setSubmitLanguage,
				triggerLanguagePrompt,
				setTriggerLanguagePrompt,
				activeLanguageId,
				setActiveLanguageId,
				newLanguage,
				setNewLanguage,
				areaOfCrud,
				setAreaOfCrud,
				videoUrl,
				interestsList,
				setInterestsList,
				provInterest,
				setProvInterest,
				toggleLocationPicture,
				setToggleLocationPicture,
				locationImageUrl,
				setLocationImageUrl,
				providerLocations,
				setProviderLocations,
				insuranceList,
				setInsuranceList,
				provInsuranceList,
				setProvInsuranceList,
				insurancePreviousItems,
				setInsurancePreviousItems,
				triggerInsurancePrompt,
				setTriggerInsurancePrompt,
				activeInsuranceId,
				setActiveInsuranceId,
				triggerCrudPrompt,
				setTriggerCrudPrompt,
				toggleCrudEditing,
				setToggleCrudEditing,
				specialRecognitionsPreviousItems,
				setSpecialRecognitionsPreviousItems,
				specialRecognitionsList,
				setSpecialRecognitionsList,
				currentRecognitionName,
				setCurrentRecognitionName,
				currentRecognitionId,
				setCurrentRecognitionId,
				numberOfRecognitions,
				setNumberOfRecognitions,
				recognitionsError,
				setRecognitionsError,
				toggleAffiliations,
				setToggleAffiliations,
				provHospitalList,
				setProvHospitalList,
				provMedGroupList,
				setProvMedGroupList,
				medGroupsList,
				setMedGroupsList,
				hospitalsList,
				setHospitalsList,
				toggleLocationScheduling, 
				setToggleLocationScheduling,
				scheduleStatus, 
				setScheduleStatus,
				scheduleType, 
				setScheduleType,
				scheduleCtaUrl, 
				setScheduleCtaUrl,
				scheduleCtaLabel, 
				setScheduleCtaLabel,
				scheduleTitle, 
				setScheduleTitle,
				scheduleDescription, 
				setScheduleDescription,
				scheduleDowntimeMessage, 
				setScheduleDowntimeMessage,
				scheduleParams, 
				setScheduleParams,
				schedUrlError, 
				setSchedUrlError,
				toggleLocationTags,
				setToggleLocationTags,
				placeTags,
				setPlaceTags,
				tagsResource,
				setTagsResource,
				locationExtUrl,
				setLocationExtUrl,
				yextPubStatus,
				setYextPubStatus,
				yextBusinessName,
				setYextBusinessName,
				yextBusinessDesc,
				setYextBusinessDesc
			}}
		>
			{props.children}
		</AppContext.Provider>
	);
};
