/**
 * File containing TableGrid as a component
 * @function
 */

import React, { useState, useMemo, useEffect } from 'react';
import Pencil from '../assets/Pencil';
import { string, array } from 'prop-types';
import TextField from 'sharp-component-library/build/TextField';
import Select from 'sharp-component-library/build/Select';
import { ChevronDown, ChevronRight } from 'sharp-component-library/build/Icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import {
	useReactTable,
	getCoreRowModel,
	getFilteredRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFacetedMinMaxValues,
	getPaginationRowModel,
	getSortedRowModel,
	flexRender,
	getExpandedRowModel
} from '@tanstack/react-table';

const customFilterFn = (row, columnId, value) => {
	return row.getValue(columnId) !== null && row.getValue(columnId).toString().toLowerCase().indexOf(value.toString().toLowerCase()) > -1;
};

const renderLabel = (label) => {
	if( typeof label === 'boolean' ){
		return label ? 'Yes': 'No';
	} else {
		return String(label == null ? '' : label);
	}
};

const Filter = ({ column, table }) => {
	const firstValue = table
	  .getPreFilteredRowModel()
	  .flatRows[0]?.getValue(column.id);
  
	const columnFilterValue = column.getFilterValue();
  
	const sortedUniqueValues = React.useMemo(
	  () =>
		typeof firstValue === 'number'
		  ? []
		  : Array.from(column.getFacetedUniqueValues().keys()).sort(),
	  [column.getFacetedUniqueValues()]
	);

	return typeof firstValue === 'number' || column.id.indexOf('name') == 0 || column.id.indexOf('slug') == 0 || sortedUniqueValues.length > 100 ? (
		<DebouncedInput
			value={columnFilterValue || ''}
			onChange={(value) => column.setFilterValue(value)}
			column={column.id}
		/>	
	) : (
		<>
		{ sortedUniqueValues.length && !(sortedUniqueValues.length === 1 && sortedUniqueValues[0] === null) ? (
			<Select
				aria-label='Filter'
				name={`${column.id}-filter`}
				className='column-filter-select'
				value={String(columnFilterValue || '')}
				onChange={(e) => column.setFilterValue(e.target.selectedOptions[0].value)}
				data-testid={`${column.id}-column-filter-select`}
			>
				<option
					key={`column-filter-new-option`}
					className='option'
					value=''
				></option>
				{sortedUniqueValues.map((option, index) => {
					return option && (
						<option
							key={`column-filter-${option}-option`}
							className='option'
							value={String(option)}
						>
							{renderLabel(option)}
						</option>
					);
				})}
			</Select>
		) : null }
		</>
	)
};



// A debounced input react component
function DebouncedInput({ value, onChange, debounce = 500, ...props }) {
	const [filterValue, setFilterValue] = useState('');

	useEffect(() => {
		const timeout = setTimeout(() => {
			onChange(filterValue);
		}, debounce);

		return () => clearTimeout(timeout);
	}, [filterValue]);

	return (
		<TextField
			hideLabel='true'
			value={filterValue}
			className={'column-filter'}
			data-testid={`${props.column}-column-filter`}
			placeholder='Search...'
			onChange={(e) => setFilterValue(e.target.value)}
		/>
	);
}

const TableGrid = ({
	componentName,
	title,
	data,
	cols,
	sortColumnId,
	globalFilter,
	filterColumns,
}) => {
	const navigate = useNavigate();
	const [columnFilters, setColumnFilters] = useState([]);
	const colsArr = cols
		.map((c) => {
			if(c.key == 'name' && title == 'specialties') {
				return {
					accessorKey: c.key,
					editOnly: c.editOnly,
					filterFn: customFilterFn,
					getColumnCanGlobalFilter: true,
					header: () => c.label,
					cell: ({ row, getValue }) => (
						<div
							className={row.depth > 0 ? 'a-child' : ''}
							style={{
								paddingLeft: `${row.depth * 2}rem`,
							}}
						>
							<>
								{row.getCanExpand() && (
									<button
										{...{
											onClick: row.getToggleExpandedHandler(),
											style: { cursor: 'pointer' },
										}}
									>
										{row.getIsExpanded() ? <FontAwesomeIcon className="icon-chevron" icon={ChevronDown} /> : <FontAwesomeIcon className="icon-chevron" icon={ChevronRight} /> }
									</button>
								)}{' '}
								{getValue()}
							</>
						</div>
					),
					hide: c.hide	
				};
			} else {
				return {
					accessorKey: c.key,
					editOnly: c.editOnly,
					filterFn: customFilterFn,
					getColumnCanGlobalFilter: true,
					header: () => c.label,
					cell: info => renderLabel(info.getValue()),
					hide: c.hide	
				};
			}
		})
		.filter((c) => !c.editOnly && !c.hide);

	const renderEditButton = (id) => {
		return (
			<button
				role='edit-btn'
				onClick={() => navigate(`${id}`)}
			>
				<Pencil />
			</button>
		);
	};

	const columns = useMemo(
		() => [
			{
				id: title,
				show: false,
				columns: [
					...colsArr,
					{
						id: 'edit',
						header: () => 'Edit',
						cell: (props) => renderEditButton(props.row.original.id),
					},
				],
			},
		],
		[title, colsArr]
	);

	const [expanded, setExpanded] = useState({})

	const table = useReactTable({
		data,
		columns,
		state: {
			globalFilter,
			columnFilters,
			expanded
		},
		initialState: {
			pagination: {
				pageSize: 50,
			},
			sorting: [
				{
					id: sortColumnId,
					desc: false,
				},
			],
		},
		enableColumnFilters: !!filterColumns,
		onColumnFiltersChange: setColumnFilters,
		globalFilterFn: customFilterFn,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		onExpandedChange: setExpanded,
		getSubRows: row => row.subRows,
		getExpandedRowModel: getExpandedRowModel(),
	});
	return (
		<>
			<table
				className={componentName}
				data-testid={componentName}
				role='results'
			>
				<thead>
					{table.getHeaderGroups().map((headerGroup, index) => {
						return (
							index !== 0 && (
								<tr key={headerGroup.id}>
									{headerGroup.headers.map((header) => {
										return (
											<th key={header.id} colSpan={header.colSpan} className={`column-header columnid-${header.id}`}>
												{header.isPlaceholder ? null : (
													<>
														<div
															{...{
																className: header.column.getCanSort()
																	? 'cursor-pointer select-none'
																	: '',
																onClick:
																	header.column.getToggleSortingHandler(),
															}}
														>
															{flexRender(
																header.column.columnDef.header,
																header.getContext()
															)}
															{{
																asc: ' 🔼',
																desc: ' 🔽',
															}[header.column.getIsSorted()] ?? null}
														</div>
														{header.column.getCanFilter() ? (
															<div>
																<Filter
																	column={header.column}
																	table={table}
																/>
															</div>
														) : null}
													</>
												)}
											</th>
										);
									})}
								</tr>
							)
						);
					})}
				</thead>
				{data && (
					<tbody data-testid='data-rows'>
						{table.getRowModel().rows.map((row) => (
							<tr key={row.id} role='data-row'>
								{row.getVisibleCells().map((cell) => (
									<td key={cell.id} className={`cell-${cell.column.id}`}>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</td>
								))}
							</tr>
						))}
						{!data.length && <tr></tr>}
					</tbody>
				)}
			</table>
			<div className='flex items-center gap-2'>
				<button
					className='border rounded p-1'
					onClick={() => table.setPageIndex(0)}
					disabled={!table.getCanPreviousPage()}
					data-testid='first-page-btn'
				>
					{'<<'}
				</button>
				<button
					className='border rounded p-1'
					onClick={() => table.previousPage()}
					disabled={!table.getCanPreviousPage()}
					data-testid='prev-page-btn'
				>
					{'<'}
				</button>
				<button
					className='border rounded p-1'
					onClick={() => table.nextPage()}
					disabled={!table.getCanNextPage()}
					data-testid='next-page-btn'
				>
					{'>'}
				</button>
				<button
					className='border rounded p-1'
					onClick={() => table.setPageIndex(table.getPageCount() - 1)}
					disabled={!table.getCanNextPage()}
					data-testid='last-page-btn'
				>
					{'>>'}
				</button>
				<span className='flex items-center gap-1'>
					Page {table.getState().pagination.pageIndex + 1} of{' '}
					{table.getPageCount()}
				</span>
				<span>|</span>
				<span className='flex items-center gap-1'>Go to page:</span>
				<input
					type='number'
					defaultValue={table.getState().pagination.pageIndex + 1}
					onChange={(e) => {
						const page = e.target.value ? Number(e.target.value) - 1 : 0;
						table.setPageIndex(page);
					}}
					className='page-input rounded'
					data-testid='page-selector-input'
				/>
				<select
					className='pagesize-select rounded'
					value={table.getState().pagination.pageSize}
					onChange={(e) => {
						table.setPageSize(Number(e.target.value));
					}}
					data-testid='pagesize-select'
				>
					{[10, 25, 50, 100].map((pageSize) => (
						<option key={pageSize} value={pageSize}>
							Show {pageSize}
						</option>
					))}
				</select>
			</div>
			<p>{data.length} Rows</p>
		</>
	);
};

TableGrid.propTypes = {
	/**
	 * Define component name
	 */
	componentName: string,
	/**
	 * Returns data response
	 */
	data: array,
	/**
	 * resource name for the data
	 */
	cols: array,
	/**
	 * ID of the column for default sorting
	 */
	sortColumnId: string,
};

TableGrid.defaultProps = {
	componentName: 'table-home',
};

export default TableGrid;
